import { ApolloClient, ApolloLink, InMemoryCache, concat } from '@apollo/client';
import { authLink } from './links/auth';
import { httpLink } from './links/http';
import { retryLink } from './links/retry';
import { errorLink } from './links/error';

declare global {
  interface Window {
    __ENV__: any;
  }
}
const cache = new InMemoryCache();

//const link = ApolloLink.from([retryLink, authLink, errorLink, httpLink]);

const link = __GRAPH_API__
  ? ApolloLink.from([retryLink, errorLink, concat(authLink, httpLink)])
  : undefined;

export const client = new ApolloClient({
  link,
  name: 'rt_portal',
  cache,
});
