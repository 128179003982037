export const styles = {
  global: {
    '@keyframes bouncedelay': {
      '0%, 80%, 100%': {
        transform: 'scale(0)'
      },
      '40%': {
        transform: 'scale(1)'
      }
    },
    'html, body': {
      backgroundColor: 'custom.backgroundColor',
      color: 'white',
      fontSize: '16px',
      fontStyle: 'normal',
      lineHeight: '24px'
    },
    h1: {
      color: 'custom.secondary',
      fontSize: '2.5em'
    },
    h2: {
      fontSize: '30px !important',
      span: {
        fontSize: '30px !important',
        margin: '0 !important',
        padding: '0 !important'
      },
      '&.primary': {
        color: 'custom.secondary'
      }
    },
    span: {
      outline: 'unset',
      outlineOffset: 'unset'
    },
    button: {
      bg: '',
      backgroundColor: 'white',
      borderColor: 'custom.backgroundColor',
      color: 'black'
    },
    '.cog': {
      path: {
        fill: 'custom.menu.cog'
      }
    },
    '.rotate': {
      transform: 'rotate(180deg)'
    },
    '.fadeIn': {
      opacity: '1',
      transition: 'opacity 0.5s ease-in',
      '&.hide': {
        opacity: '0'
      }
    },
    '.pop-background': {
      width: '100%',
      height: '100vh',
      position: 'absolute',
      left: '0',
      top: '0',
      opacity: '0.2',
      backgroundColor: 'gray',
      zIndex: 1,
      transition: 'opacity 0.2s ease-in',
      '&.hide': {
        opacity: 0
        //zIndex: -1
      }
    }
  }
};
