import React, { PropsWithChildren, useState } from 'react';
import { SpinnerLoading } from '../components/layout/Spinner';

interface ILoadingArgs {
  isLoading: boolean;
}

const initialState: ILoadingArgs = {
  isLoading: false
};

interface LoadingContext extends PropsWithChildren {
  loadContext: ILoadingArgs;
  setIsLoading: (args: ILoadingArgs) => void;
}

export const LoadingContext = React.createContext<LoadingContext>({
  loadContext: initialState,
  setIsLoading: () => {}
});

export const LoadingContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loadContext, setIsLoading] = React.useState<ILoadingArgs>(initialState);

  return (
    <LoadingContext.Provider value={{ loadContext, setIsLoading }}>
      {children}
      <SpinnerLoading visible={loadContext?.isLoading} />
    </LoadingContext.Provider>
  );
};
