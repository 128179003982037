import { defineStyleConfig, grid } from '@chakra-ui/react';

export const template = {
  ProfileHeader: defineStyleConfig({
    variants: {
      primary: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        marginBottom: '3em',
        paddingBottom: '3em',
        borderBottom: '1px solid var(--chakra-colors-custom-profile-header-border)',
        '&.hasmsg': {
          paddingBottom: '1em'
        },
        '.info': {
          display: 'flex',
          flexDirection: 'column'
        },
        '.buttons': {
          display: 'flex',
          justifyContent: 'right',
          width: '100%'
        },
        '.msg': {
          gridColumn: 'span 2',
          paddingTop: '1em',
          span: {
            color: 'custom.profile.header.spanColor'
          }
        }
      }
    },
    defaultProps: {
      variant: 'primary'
    }
  }),
  ProfileTemplate: defineStyleConfig({
    baseStyle: {
      display: 'grid',
      maxWidth: ['100%', '100%', '100%', '1000px'],
      gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'],
      gridGap: ['0', '2em'],
      '.offices': {
        gridColumn: '1/-1'
      },
      '.section': {
        display: 'flex',
        maxWidth: ['100%', '100%', '100%', '412px'],
        flexDirection: 'column',
        ' > div': {
          paddingBottom: '1.5em',
          position: 'relative'
        },
        h2: {
          paddingBottom: '0.5em'
        },
        '.delete': {
          position: 'absolute',
          right: '0',
          top: '-4px'
        },
        '.profile-image': {
          position: 'relative',
          width: '50%',
          paddingBottom: 0,
          margin: '0 auto 1.5em auto',
          border: '1px solid var(--chakra-colors-custom-secondary)',
          '.window-preview': {
            width: '100%',
            overflow: 'hidden',
            cursor: 'pointer',
            img: {
              aspectRatio: '373 / 278',
              transformOrigin: 'top left'
            }
          },
          '.delete': {
            position: 'absolute',
            top: '-9px',
            zIndex: 1
          },
          img: {
            width: '100%'
          }
        }
      },
      '.container': {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '2em',
        paddingTop: '1em',
        '&.partners': {
          '.item': {
            textarea: {
              height: '400px'
            }
          }
        },
        '.item': {
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridGap: '0.2em',
          textarea: {
            height: '160px'
          },
          '.timeSpan': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: '2em',
            paddingBottom: '0.5em'
          },
          '.grid': {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: '0.5em'
          }
        }
      },
      '.infobox': {
        position: 'absolute',
        right: '0',
        top: '-5px'
      },
      '.image': {
        width: '100%',
        display: 'flex',
        marginTop: '2em'
      },
      '.video': {
        '.show-video': {
          marginTop: '1em',
          width: '100%'
        },
        '.video-iframe': {
          display: 'flex',
          position: 'fixed',
          width: '100%',
          height: '100vh',
          top: 0,
          left: 0,
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.50)',
          zIndex: 1,
          iframe: {
            width: '100%',
            aspectRatio: '16/9'
          },
          div: {
            display: 'flex'
          },
          '.content': {
            margin: 'auto',
            flexDirection: 'column',
            width: '90%',
            maxWidth: '800px',
            aspectRatio: '16/9',
            padding: '1em',
            borderRadius: '5px',
            borderColor: 'custom.secondary',
            height: 'fit-content',
            '.button-wrapper': {
              paddingBottom: '0.5em',
              justifyContent: 'end'
            }
          }
        }
      }
    },
    variants: {
      primary: {},
      secondary: {
        maxWidth: ['100%', '100%', '100%', '1400px'],
        gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr 1fr'],
        '.section': {
          '&.last': {
            maxWidth: '100%'
          }
        },
        '.msgbox': {
          margin: ['0 0 2.5em 0', '0 0 2.5em 0', '0 0 2.5em 0', '2.5em 0 0 0']
        }
      }
    },
    defaultProps: {
      variant: 'secondary'
    }
  }),
  profileList: defineStyleConfig({
    variants: {
      primary: {
        '.search': {
          margin: '2em 0'
        },
        '.footer': {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          padding: '24px 12px',
          '.item-limit': {
            display: 'flex',
            alignItems: 'center',
            span: {
              paddingRight: '8px'
            },
            '.dropdown': {
              width: '75px'
            }
          },
          '.pager': {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center'
          }
        },
        '.container': {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
          '.header': {
            fontSize: '12px',
            fontWeight: '700',
            fontStyle: 'normal',
            textTransform: 'uppercase',
            height: '40px',
            span: {
              padding: '0 16px 0 8px'
            },
            '&.click': {
              cursor: 'pointer'
            },
            svg: {
              width: '16px',
              height: '20px'
            }
          },
          '> div': {
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor: 'custom.list.tableBorder',
            height: '72px',
            fontSize: '14px',
            paddingRight: '1em',
            cursor: 'pointer',
            '&.center': {
              justifyContent: 'center'
            },
            '&.last': {
              justifyContent: 'end',
              paddingRight: '1em',
              '.item': {
                position: 'relative'
              },
              '.menu': {
                position: 'absolute',
                backgroundColor: 'custom.list.rowMenu.backgroundColor',
                width: '226px',
                maxHeight: '250px',
                padding: '20px 30px',
                borderRadius: '23px',
                transition: 'opacity 0.3s ease-in-out',
                overflow: 'hidden',
                right: '0',
                zIndex: '2',
                opacity: '1',
                '&.hide': {
                  opacity: 0
                },
                div: {
                  width: '226px',
                  _hover: {
                    color: 'custom.list.rowMenu.hoverColor'
                  }
                }
              }
            }
          },
          span: {
            lineHeight: '16px',
            '&.title': {
              color: 'custom.secondary',
              fontWeight: '700'
            }
          },
          '.profile-image': {
            minHeight: '40px',
            minWidth: '40px',
            maxHeight: '40px',
            maxWidth: '40px',
            backgroundColor: 'white',
            borderRadius: '25px',
            marginRight: '12px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          },
          '.list': {
            display: 'flex',
            flexDirection: 'column'
          },
          '.status': {
            borderRadius: '2px',
            fontSize: '12px',
            backgroundColor: 'custom.list.defaultStatus',
            padding: '0 2px',
            display: 'flex',
            alignItems: 'center',
            height: '18px',
            whiteSpace: 'nowrap'
          }
        },
        '.message': {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          paddingTop: '2em',
          color: 'custom.list.messageColor'
        }
      }
    },
    defaultProps: {
      variant: 'primary'
    }
  }),
  ImageCropper: defineStyleConfig({
    baseStyle: {
      '.buttons': {
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: '1em',
        gap: '1em'
      }
    },
    variants: {
      primary: {
        display: 'flex',
        flexDirection: 'column',
        '.container': {
          position: 'relative',
          width: '100%',
          height: '40vh'
        },
        '.reactEasyCrop_Container': {
          touchAction: 'auto',
          '.remove': {
            touchAction: 'none'
          }
        },
        '.preview': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          paddingTop: '2em',
          '.output': {
            position: 'relative',
            width: '500px',
            overflow: 'hidden',
            img: {
              transformOrigin: 'top left',
              width: '100%',
              height: 'auto'
            }
          }
        },
        '.controls': {
          display: 'flex',
          width: '50%',
          height: '40px',
          margin: 'auto',
          alignItems: 'center',
          'input[type=range]': {
            width: '100%',
            height: '10px',
            borderRadius: '5px',
            '&.styled': {
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              background: 'white',
              height: '4px',
              //THUMB
              '::-moz-range-thumb': {
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                background: 'input.range.thumb.backgroundColor',
                border: '1px solid input.range.thumb.borderColor',
                borderRadius: '50%',
                width: '15px',
                height: '15px',
                transition: 'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
              },
              '::-webkit-slider-thumb': {
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                background: 'input.range.thumb.backgroundColor',
                border: '1px solid input.range.thumb.borderColor',
                borderRadius: '50%',
                width: '15px',
                height: '15px',
                cursor: 'pointer',
                boxShadow: '0',
                transition: 'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
              },
              _hover: {
                //THUMB
                '::-moz-range-thumb': {
                  boxShadow: '0 0 0 4px var(--chakra-colors-input-range-thumb-hover)'
                },
                '::-webkit-slider-thumb': {
                  boxShadow: '0 0 0 4px var(--chakra-colors-input-range-thumb-hover)'
                }
              }
            }
          }
        }
      }
    },
    defaultProps: {
      variant: 'primary'
    }
  })
};
