import { Box, useStyleConfig } from '@chakra-ui/react';
import * as React from 'react';

interface IArgs {
  visible?: boolean;
}

export const SpinnerLoading: React.FC<IArgs> = ({ visible }) => {
  const styles = useStyleConfig('Spinner');
  return (
    <Box className={visible ? `` : `hide`} __css={styles}>
      <Box id="spinner">
        <Box id="container">
          <Box className="circle" />
          <Box className="circle" />
          <Box className="circle" />
          <Box className="circle" />
        </Box>
        <Box id="container">
          <Box className="circle" />
          <Box className="circle" />
          <Box className="circle" />
          <Box className="circle" />
        </Box>
        <Box id="container">
          <Box className="circle" />
          <Box className="circle" />
          <Box className="circle" />
          <Box className="circle" />
        </Box>
      </Box>
    </Box>
  );
};
