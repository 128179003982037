import * as React from 'react';
import { Global } from '@emotion/react';

export const Fonts = () => {
  return (
    <Global
      styles={`
        @font-face {
            font-family: 'DomaineDispCondRegular';
            src: url('https://cdn.reeltime.no/pm_assets/fonts/DomaineDisplayCondensed-Regular.otf');
            font-weight: 300;
        }
        @font-face {
            font-family: 'DomaineText-Regular';
            src: url('https://cdn.reeltime.no/pm_assets/fonts/DomaineText-Regular.otf') format('opentype');
            font-weight: normal;
        }
        @font-face {
            font-family: 'Geograph-Regular';
            src: url('https://cdn.reeltime.no/pm_assets/fonts/Geograph-Regular.otf') format('opentype');
            font-weight: normal;
        }
        @font-face {
            font-family: 'Geograph-Bold';
            src: url('https://cdn.reeltime.no/pm_assets/fonts/Geograph-Bold.otf') format('opentype');
            font-weight: bold;
        }
        `}
    />
  );
};
