import loadable from "@loadable/component";

const hocs = {
    Protected: loadable(/* #__LOADABLE__ */ () => import('./hoc/Protected'))
};

const pages = {
    Login: loadable(/* #__LOADABLE__ */ () => import('./components/pages/login/Login')),
    LoginVerify: loadable(/* #__LOADABLE__ */ () => import('./components/pages/login/Verify')),
    McvProfile: loadable(/* #__LOADABLE__ */ () => import('./components/pages/meglercv/Profile')),
    McvList: loadable(/* #__LOADABLE__ */ () => import('./components/pages/meglercv/List')),
    CallbackRedirect: loadable(/* #__LOADABLE__ */ () => import('./components/utils/CallbackRedirect')),
    NoMatch: loadable(/* #__LOADABLE__ */ () => import('./components/pages/other/NoMatch')),
    NoAccess: loadable(/* #__LOADABLE__ */ () => import('./components/pages/other/NoAccess')),
    RedirectPage: loadable(/* #__LOADABLE__ */ () => import('./components/pages/other/Redirect')),
};


 export { hocs, pages };