import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { client } from './graphql/client';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';

const node = document.getElementById('root') as HTMLElement;
if (!node) console.error('mount node is not found in document');
const root = createRoot(node);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </HelmetProvider>
  </React.StrictMode>
);