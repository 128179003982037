import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { client } from '..';
import { setAccessToken } from '../../../utils/token';
import { AUTHENTICATED_QUERY } from '../../queries/login/authenticated';

let isRefreshing = false;
let pendingRequests: any[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback: any) => callback());
  pendingRequests = [];
};

const refreshToken = async () => {
  return fetch(__REFRESH_ENDPOINT__, {
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then(async (data) => {
    if (data.status !== 200) {
      pendingRequests = [];
      client.getObservableQueries().forEach((observableQuery) => {
        observableQuery.refetch();
      });
      return;
    } else {
      const contentType = data.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const { token } = await data.json();
        setAccessToken(token);
        resolvePendingRequests();
        return token;
      }
    }
  })
  .catch(() => {
    pendingRequests = [];
    return;
  })
  .finally(() => {
    isRefreshing = false;
  });
};

export const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (networkError?.message === 'Failed to fetch') {
      networkError.message = 'Could not establish contact with service';
    }
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            if (err.extensions.refresh) {
              isRefreshing = true;
              let forward$ = null;
              if (isRefreshing) {
                forward$ = fromPromise(refreshToken());
              } else {
                forward$ = fromPromise(
                  new Promise((resolve) => {
                    pendingRequests.push(() => resolve(true));
                  })
                );
              }
              return forward$.flatMap(() => forward(operation));
            }
        }
        return forward(operation);
      }
    }
  }
);