import { defineStyleConfig, ring } from '@chakra-ui/react';

export const boxStyles = {
  BoxStyles: defineStyleConfig({
    variants: {
      center: {
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        padding: ['0em 1em', 'unset'],
        alignItems: 'center',
        '.wrapper': {
          width: ['75%', '70%'],
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1em'
        }
      },
      logo: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '69px',
        width: '69px',
        margin: '0 auto'
      },
      infoBox: {
        width: '100%',
        '.icon': {
          cursor: 'pointer',
          height: '21px',
          width: '21px',
          backgroundColor: 'infoBox.backgroundColor',
          color: 'infoBox.color',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          position: 'absolute',
          right: 0
        },
        '.popbox': {
          display: 'none',
          backgroundColor: 'infoBox.pop.backgroundColor',
          color: 'infoBox.pop.color',
          maxHeight: 0,
          width: 0,
          position: 'absolute',
          top: '0',
          right: '0',
          zIndex: '1',
          borderRadius: '23px 0px 23px 23px',
          padding: '25px',
          gridTemplateColumns: '1fr',
          gridGap: '0.3em',
          transition: 'all 0.25s ease-in',
          overflow: 'hidden',
          opacity: 0,
          '&.show': {
            maxHeight: '500px',
            width: '100%',
            transform: 'scale(1)',
            opacity: 1
          },
          '.wrapper': {
            display: 'flex',
            justifyContent: 'end',
            '.close': {
              cursor: 'pointer',
              width: '16px',
              height: '16px'
            }
          },
          '.download': {
            marginTop: '1em',
            textDecoration: 'underline'
          }
        }
      },
      MessageBox: {
        backgroundColor: 'custom.msgBox.backgroundColor',
        padding: '32px 57px',
        a: { color: 'custom.msgBox.a.color' }
      },
      message: {
        display: 'flex',
        backgroundColor: '#E1D6D8',
        color: 'black',
        fontSize: '16px',
        padding: '1em',
        alignItems: 'start',
        borderRadius: '10px',
        position: 'fixed',
        bottom: '2em',
        right: '1em',
        justifyContent: 'end',
        '&.error': {
          color: 'white',
          backgroundColor: '#E2182F',
          svg: {
            path: { stroke: 'white' }
          }
        },
        '.message': {
          paddingRight: '1em'
        }
      }
    },
    defaultProps: {
      variant: 'center'
    }
  }),
  MenuBox: defineStyleConfig({
    variants: {
      profile: {
        backgroundColor: 'white',
        borderRadius: '36px',
        width: '72px',
        height: '72px',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'end'
      },
      cog: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '33px',
        height: '33px',
        borderRadius: '18px',
        backgroundColor: 'custom.backgroundColor',
        position: 'relative',
        top: '8px',
        left: '5px',
        cursor: 'pointer',
        transform: 'rotate(0deg)',
        transition: 'transform 1s ease-in-out',
        _hover: {
          transform: 'rotate(180deg)'
        }
      },
      menu: {
        minWidth: '4em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        height: '100vh',
        background: 'custom.menu.backgroundColor',
        transition: 'all 0.4s ease-in-out',
        '.content': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center'
        },
        '.logo': {
          margin: '0 auto 1.5em auto',
          height: '36px',
          width: '37px',
          svg: {
            height: '36px',
            width: '36px'
          }
        },
        '.options': {
          width: '100%',
          padding: '1.5em 1em 0em 0em',
          display: 'flex',
          flexDirection: 'column',
          a: {
            color: 'custom.menu.option.color',
            _hover: {
              color: 'custom.menu.option.hoverColor'
            }
          }
        },
        '&.open': {
          minWidth: '235px',
          padding: '0 3em'
        },
        '.top': {
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
          padding: '1.5em 0 1em 0'
        }
      }
    },
    defaultProps: {
      variant: 'menu'
    }
  }),
  BoxButton: defineStyleConfig({
    baseStyle: {
      cursor: 'pointer'
    },
    variants: {
      big: {
        width: '100%',
        borderWidth: '2px',
        borderStyle: 'dashed',
        minHeight: '156px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '23px',
        borderColor: 'button.big.borderColor',
        flexDirection: 'column',
        textAlign: 'center',
        _hover: {
          '#pluss': {
            transform: 'scale(1.2)'
          }
        },
        '.info': {
          paddingTop: '0.5rem',
          fontWeight: '400',
          fontSize: '15px'
        },
        '#pluss': {
          display: 'flex',
          color: 'black',
          backgroundColor: 'button.big.borderColor',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '20px',
          width: '44px',
          height: '44px',
          borderRadius: '22px',
          transform: 'scale(1)',
          transition: 'transform 0.5s ease-in-out'
        }
      },
      remove: {
        display: 'flex',
        backgroundColor: 'custom.secondary',
        height: '21px',
        width: '21px',
        color: 'black',
        borderRadius: '10px',
        justifyContent: 'center',
        alignItems: 'center'
      },
      menu: {
        color: 'white',
        transition: 'color 0.3s ease-in',
        marginTop: '3em',
        svg: {
          height: '25px',
          width: '25px'
        },
        _hover: {
          color: 'custom.secondary'
        }
      },
      menuClose: {
        color: 'white',
        transition: 'color 0.3s ease-in',
        svg: {
          height: '16px',
          width: '16px',
          path: {
            stroke: '#D5D5D5'
          }
        },
        _hover: {
          color: 'custom.secondary'
        }
      },
      msgClose: {
        color: 'black',
        height: '16px',
        width: '16px',
        paddingTop: '3px',
        svg: {
          height: '16px',
          width: '16px',
          path: {
            stroke: 'black'
          }
        }
      }
    },
    defaultProps: {
      variant: 'big'
    }
  }),
  DropDownList: defineStyleConfig({
    baseStyle: {
      '.ddl__control': {
        height: '51px',
        borderRadius: '10px',
        borderWidth: '0',
        cursor: 'pointer',
        _hover: {
          borderWidth: '0px',
          borderColor: 'unset',
          boxShadow: 'unset'
        }
      },
      '.ddl__indicator-separator': {
        display: 'none'
      },
      '.ddl__dropdown-indicator': {
        transform: '',
        transition: '250ms'
      },
      '.ddl__menu': {
        backgroundColor: 'transparent'
      },
      '.ddl__menu-list': {
        borderRadius: '10px'
      },
      '.ddl__option': {
        cursor: 'pointer'
      }
    },
    variants: {
      primary: {
        '.ddl__control': {
          backgroundColor: 'select.primary.backgroundColor',
          color: 'select.primary.color',
          borderColor: 'select.primary.borderColor',
          paddingLeft: '12px'
        },
        '.ddl__dropdown-indicator': {
          color: 'select.primary.icon',
          _hover: {
            color: 'select.primary.icon'
          },
          svg: {
            height: '30px',
            width: '50px'
          }
        },
        '.ddl__menu-list': {
          backgroundColor: 'select.primary.backgroundColor',
          color: 'select.primary.color'
        },
        '.ddl__option': {
          backgroundColor: 'select.primary.backgroundColor',
          color: 'select.primary.color',
          _hover: {
            backgroundColor: 'select.primary.hover.backgroundColor',
            color: 'select.primary.hover.color'
          }
        },
        '.ddl__single-value': {
          color: 'select.primary.hover.backgroundColor'
        }
      },
      secondary: {
        '.ddl__control': {
          height: '32px',
          backgroundColor: 'select.secondary.backgroundColor',
          color: 'select.secondary.color',
          borderColor: 'select.secondary.borderColor'
        },
        '.ddl__dropdown-indicator': {
          color: 'select.secondary.icon',
          _hover: {
            color: 'select.secondary.icon'
          }
        },
        '.ddl__menu-list': {
          backgroundColor: 'select.secondary.backgroundColor',
          color: 'select.secondary.color'
        },
        '.ddl__option': {
          backgroundColor: 'select.secondary.backgroundColor',
          color: 'select.secondary.color',
          _hover: {
            backgroundColor: 'select.secondary.hover.backgroundColor',
            color: 'select.secondary.hover.color'
          }
        },
        '.ddl__single-value': {
          color: 'select.secondary.hover.backgroundColor'
        }
      }
    },
    defaultProps: {
      variant: 'primary'
    }
  }),
  Spinner: defineStyleConfig({
    baseStyle: {
      backgroundColor: 'custom.spinner.backgroundColor',
      bottom: '0',
      left: '0',
      margin: 'auto',
      position: 'fixed',
      right: '0',
      top: '0',
      zIndex: '10',
      width: '100%',
      height: '100%',
      display: 'flex',
      '&.hide': {
        display: 'none'
      },
      '#spinner': {
        width: '70px',
        height: '70px',
        position: 'absolute',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        margin: 'auto'
      },
      '#container': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        '&:nth-of-type(1)': {
          div: {
            '&:nth-of-type(2)': {
              animationDelay: '-0.9s'
            },
            '&:nth-of-type(3)': {
              animationDelay: '-0.6s'
            },
            '&:nth-of-type(4)': {
              animationDelay: '-0.3s'
            }
          }
        },
        '&:nth-of-type(2)': {
          transform: 'rotateZ(45deg)',
          div: {
            '&:nth-of-type(1)': {
              animationDelay: '-1.1s'
            },
            '&:nth-of-type(2)': {
              animationDelay: '-0.8s'
            },
            '&:nth-of-type(3)': {
              animationDelay: '-0.5s'
            },
            '&:nth-of-type(4)': {
              animationDelay: '-0.2s'
            }
          }
        },
        '&:nth-of-type(3)': {
          transform: 'rotateZ(90deg)',
          div: {
            '&:nth-of-type(1)': {
              animationDelay: '-1.0s'
            },
            '&:nth-of-type(2)': {
              animationDelay: '-0.7s'
            },
            '&:nth-of-type(3)': {
              animationDelay: '-0.4s'
            },
            '&:nth-of-type(4)': {
              animationDelay: '-0.1s'
            }
          }
        }
      },
      '.circle': {
        width: '20px',
        height: '20px',
        backgroundColor: 'custom.spinner.dotColor',
        borderRadius: '100%',
        position: 'absolute',
        animation: 'bouncedelay 1.2s infinite ease-in-out',
        animationFillMode: 'both',
        //animation: '.5s linear 1s infinite alternate slidein',
        '&:nth-of-type(1)': {
          top: '0',
          left: '0'
        },
        '&:nth-of-type(2)': {
          top: '0',
          right: '0'
        },
        '&:nth-of-type(3)': {
          bottom: '0',
          right: '0'
        },
        '&:nth-of-type(4)': {
          bottom: '0',
          left: '0'
        }
      }
    },
    variants: {
      primary: {}
    },
    defaultProps: {
      variant: 'primary'
    }
  }),
  OfficeTemplate: defineStyleConfig({
    baseStyle: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      gap: '0.5em'
    }
  })
};
