import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';
import { styles } from './styles';
import { components } from './components';

export const defaultTheme = {
  logo: 'url("https://cdn.reeltime.no/pm_assets/logo/pm.svg")',
  favicon: 'https://cdn.reeltime.no/pm_assets/img/favicons/favicon-32x32.png',
  fonts: {
    body: `'Geograph-Regular'`,
    heading: `'DomaineText-Regular'`,
    h2: `'DomaineText-Regular'`
  },
  fontSizes: {
    sm: '16px'
  },
  styles,
  components,
  colors: {
    palette: {
      champage: '#E7CFA7',
      licorice: '#171719',
      background: '#1E1E1E'
    },
    custom: {
      profile: {
        header: {
          border: '#4B4B4B',
          spanColor: 'var(--chakra-colors-palette-champage)'
        }
      },
      backgroundColor: 'var(--chakra-colors-palette-licorice)',
      secondary: 'var(--chakra-colors-palette-champage)',
      menu: {
        cog: 'var(--chakra-colors-palette-champage)',
        backgroundColor: 'linear-gradient(179.9deg, rgba(0, 0, 0, 0.21) -4.32%, rgba(23, 23, 25, 0) 107.56%)',
        color: 'white',
        option: {
          //color: '#4B4B4B',
          color: 'white',
          hoverColor: 'var(--chakra-colors-palette-champage)'
        }
      },
      list: {
        messageColor: '#E7CFA7',
        tableBorder: '#4B4B4B',
        defaultStatus: 'red',
        rowMenu: {
          backgroundColor: '#4B4B4B',
          hoverColor: '#E7CFA7'
        },
        footer: {
          ddlBackgroundColor: '#E7CFA7',
          ddlColor: 'black'
        }
      },
      msgBox: {
        backgroundColor: '#202020',
        borderColor: '#000000',
        boxShadow: 'rgba(0, 0, 0, 0.25)',
        a: {
          color: 'var(--chakra-colors-palette-champage)'
        }
      },
      spinner: {
        //backgroundColor: 'rgba(255, 255, 255, 0.5)',
        //dotColor: '#091E3C'
        backgroundColor: 'rgba(23, 23, 25, 0.7)',
        dotColor: 'var(--chakra-colors-palette-champage)'
      }
    },
    login: {
      box: {
        backgroundColor: '#242525',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
      },
      label: '#E7CFA7'
    },
    button: {
      primary: {
        backgroundColor: '#E7CFA7',
        borderColor: '#1E1E1E',
        color: 'black',
        hover: {
          backgroundColor: '#1E1E1E',
          borderColor: '#E7CFA7',
          color: '#E7CFA7'
        }
      },
      secondary: {
        backgroundColor: '#1E1E1E',
        borderColor: '#E7CFA7',
        color: 'white',
        hover: {
          color: 'black',
          backgroundColor: '#E7CFA7',
          borderColor: '#E7CFA7'
        }
      },
      logout: {
        backgroundColor: '#242525',
        color: 'white',
        hover: {
          backgroundColor: '#E7CFA7',
          color: 'black'
        }
      },
      big: {
        borderColor: '#E7CFA7'
      },
      third: {
        backgroundColor: '#242525',
        color: 'white',
        borderColor: '#242525',
        hover: {
          color: '#E7CFA7'
        }
      }
    },
    input: {
      primary: {
        backgroundColor: '#4B4B4B',
        color: 'white',
        placeholder: '#A0A0A0'
      },
      range: {
        thumb: {
          backgroundColor: '#E7CFA7',
          borderColor: '#E7CFA7',
          hover: 'rgba(231, 207, 167, 0.5)'
        }
      }
    },
    select: {
      primary: {
        backgroundColor: '#4B4B4B',
        color: '#A0A0A0',
        icon: '#E7CFA7',
        borderColor: null,
        borderWidth: '0',
        hover: {
          backgroundColor: '#E7CFA7',
          color: 'black'
        }
      },
      secondary: {
        backgroundColor: '#E7CFA7',
        color: 'black',
        icon: '#171719',
        borderColor: null,
        borderWidth: '0',
        hover: {
          backgroundColor: '#171719',
          color: '#E7CFA7'
        }
      }
    },
    infoBox: {
      backgroundColor: '#E7CFA7',
      color: 'black',
      pop: {
        backgroundColor: 'white',
        color: 'black'
      }
    }
  }
};
