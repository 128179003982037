import * as React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { extendTheme } from '@chakra-ui/react';
import { defaultTheme } from './theme';
import { Fonts } from './theme/fonts';

import { hocs, pages } from './Register';

//Hooks
import { AuthenticationProvider } from './context/Authenticaton';
import { LoadingContextProvider } from './context/LoadingContext';

const { Protected } = hocs;
const { CallbackRedirect, Login, LoginVerify, McvList, McvProfile, NoMatch, NoAccess, RedirectPage } = pages;

export const App: React.FC = () => {
  //const { data, loading, error } = useSiteQuery();
  const [brandId, SetBrandId] = React.useState('');
  //use query basert på bandid eller origin

  React.useEffect(() => {
    if (defaultTheme?.favicon) {
      let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = defaultTheme.favicon;
    }
  }, [defaultTheme?.favicon]);

  return (
    <ChakraProvider theme={extendTheme(defaultTheme)}>
      <Fonts />
      <AuthenticationProvider>
        <LoadingContextProvider>
          <Routes>
            <Route path="/initialize" element={<CallbackRedirect />} />
            {/*<Route path='login'>
                        <Route path='sso/:key' element={<LoginVerify />} />
                        <Route path='sso/' element={<LoginVerify />} />
                        <Route index element={<Login />} />
                    </Route>*/}
            <Route
              path="/"
              element={
                <Protected>
                  <Outlet />
                </Protected>
              }
            >
              <Route index path="/meglercv" element={<McvProfile />} />
              <Route path="/meglercv/profile" element={<McvProfile />} />
              <Route path="/meglercv/profile/:profileId" element={<McvProfile />} />
              <Route path="/meglercv/list" element={<McvList />} />
              <Route path="/meglercv/*" element={<NoMatch />} />
              <Route index element={<RedirectPage />} />
            </Route>
            <Route
              path="*"
              element={
                <Protected>
                  <Outlet />
                </Protected>
              }
            >
              <Route element={<NoMatch />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
            <Route path="/fant-ikke-siden" element={<NoMatch />} />
            <Route path="/no-access" element={<NoAccess />} />
          </Routes>
        </LoadingContextProvider>
      </AuthenticationProvider>
    </ChakraProvider>
  );
};
