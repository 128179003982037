import { boxStyles } from './boxStyles';
import { template } from './profileTemplate';
import { defineStyleConfig } from '@chakra-ui/react';

export const components = {
  ...boxStyles,
  ...template,
  Link: defineStyleConfig({
    variants: {
      menu: {
        color: 'custom.menu.option.color',
        fontWeight: '400',
        width: '100%',
        _hover: {
          color: 'custom.menu.option.hoverColor',
          textDecoration: 'none'
        },
        _notLast: {
          paddingBottom: '0.5em'
        }
      },
      button: {
        display: 'flex',
        alignItems: 'center',
        borderWidth: '1px',
        borderRadius: '25px',
        whiteSpace: 'nowrap',
        _disabled: {
          cursor: 'unset'
        },
        bg: 'button.third.backgroundColor',
        color: 'button.third.color',
        borderColor: 'button.third.borderColor',
        padding: '8px 16px',
        _hover: {
          color: 'button.third.hover.color',
          textDecoration: 'unset'
        },
        height: '2.5rem'
      }
    }
  }),
  Tag: defineStyleConfig({
    baseStyle: {
      container: {
        backgroundColor: 'unset',
        boxShadow: 'none',
        borderWidth: '0',
        outline: 'unset',
        outlineOffset: 'unset',
        fontSize: '16px'
      }
    },
    variants: {
      primary: {
        container: {
          color: 'custom.secondary',
          padding: '0 0 0.5em 0',
          fontWeight: '700',
          '&.white': {
            color: 'white'
          },
          '&.space': {
            paddingLeft: '0.5em'
          }
        }
      },
      status: {
        container: {
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          backgroundColor: '#E08752',
          color: 'white',
          height: '18px',
          lineHeight: '18px',
          width: 'fit-content',
          fontSize: '12px',
          borderRadius: '0'
        }
      }
    },
    defaultProps: {
      variant: 'primary'
    }
  }),
  Button: defineStyleConfig({
    baseStyle: {
      borderWidth: '1px',
      borderRadius: '25px',
      _disabled: {
        cursor: 'unset'
      }
    },
    variants: {
      primary: {
        bg: 'button.primary.backgroundColor',
        color: 'button.primary.color',
        borderColor: 'button.primary.borderColor',
        _hover: {
          color: 'button.primary.hover.color',
          bg: 'button.primary.hover.backgroundColor',
          borderColor: 'button.primary.hover.borderColor',
          _disabled: {
            bg: 'button.primary.backgroundColor',
            color: 'button.primary.color',
            borderColor: 'button.primary.borderColor'
          }
        }
      },
      secondary: {
        bg: 'button.secondary.backgroundColor',
        color: 'button.secondary.color',
        borderColor: 'button.secondary.borderColor',
        _hover: {
          color: 'button.secondary.hover.color',
          bg: 'button.secondary.hover.backgroundColor',
          borderColor: 'button.secondary.hover.borderColor',
          _disabled: {
            bg: 'button.secondary.backgroundColor',
            color: 'button.secondary.color',
            borderColor: 'button.secondary.borderColor'
          }
        }
      },
      third: {
        bg: 'button.third.backgroundColor',
        color: 'button.third.color',
        borderColor: 'button.third.borderColor',
        padding: '8px 16px',
        _hover: {
          color: 'button.third.hover.color'
        }
      },
      logout: {
        bg: 'button.logout.backgroundColor',
        borderWidth: '0',
        color: 'button.logout.color',
        marginTop: '2em',
        width: 'min-content',
        boxShadow: '0px 0px 10px rgba(255, 255, 255, 0.03)',
        _hover: {
          bg: 'button.logout.hover.backgroundColor',
          color: 'button.logout.hover.color'
        }
      },
      pager: {
        bg: 'unset',
        color: '#E7CFA7',
        maxWidth: '32px',
        height: '32px',
        padding: 0,
        alignItems: 'center',
        borderWidth: '0',
        borderRadius: '0',
        _hover: {
          color: '#E7CFA7'
        },
        '&.prev': {
          transform: 'rotate(180deg)'
        },
        svg: {
          margin: 'auto',
          height: '1em',
          width: '1em',
          path: {
            fill: '#E7CFA7'
          }
        },
        _disabled: {
          color: '#4B4B4B',
          svg: {
            path: {
              fill: '#4B4B4B'
            }
          }
        }
      }
    },
    defaultProps: {
      variant: 'primary'
    }
  }),
  Textarea: defineStyleConfig({
    variants: {
      primary: {
        bg: 'input.primary.backgroundColor',
        color: 'input.primary.color',
        _placeholder: {
          color: 'input.primary.placeholder'
        }
      }
    },
    defaultProps: {
      variant: 'primary'
    }
  }),
  Input: defineStyleConfig({
    variants: {
      primary: {
        field: {
          bg: 'input.primary.backgroundColor',
          color: 'input.primary.color',
          _placeholder: {
            color: 'input.primary.placeholder'
          }
        }
      },
      search: {
        field: {
          bg: 'custom.backgroundColor'
        }
      }
    },
    defaultProps: {
      variant: 'primary'
    }
  }),
  Select: defineStyleConfig({
    variants: {
      primary: {
        field: {
          bg: 'select.primary.backgroundColor',
          color: 'select.primary.color',
          height: '51px',
          borderRadius: '10px',
          option: {
            bg: 'select.primary.backgroundColor',
            border: 'unset',
            margin: '0.5em'
          }
        },
        icon: {
          color: 'select.primary.icon'
        }
      }
    },
    defaultProps: {
      variant: 'primary'
    }
  })
};
