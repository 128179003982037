import React, { PropsWithChildren, useState } from 'react';

interface IAuthenticationInitArgs {
  loggedIn: boolean;
  scopes: any[];
  name: string;
  isAdmin: boolean;
  brandId: string;
}
interface IAuhtenticationContext extends PropsWithChildren {
  loggedIn: boolean;
  scopes: any[] | null | undefined;
  name: string | null | undefined;
  brandId: string | null | undefined;
  isAdmin: boolean;
  init: (args: IAuthenticationInitArgs) => void;
}

const defaultContext: IAuhtenticationContext = {
  loggedIn: false,
  isAdmin: false,
  scopes: null,
  name: null,
  brandId: null,
  init: () => {}
};

export const AuthenticationContext = React.createContext<IAuhtenticationContext>(defaultContext);

export const AuthenticationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [brandId, setBrandId] = useState<string | null>(null);
  const [scopes, setScopes] = useState<any[] | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const init = (args: { scopes: any[]; name: string; loggedIn: boolean; isAdmin: boolean; brandId: string }) => {
    setBrandId(args.brandId);
    setScopes(args.scopes);
    setName(args.name);
    setLoggedIn(args.loggedIn);
    setIsAdmin(args.isAdmin);
  };

  return <AuthenticationContext.Provider value={{ init, scopes, name, loggedIn, isAdmin, brandId }}>{children}</AuthenticationContext.Provider>;
};
